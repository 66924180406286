import { IoClose } from "react-icons/io5";
import { NavLink } from 'react-router-dom';
import { RxDividerHorizontal } from "react-icons/rx";
import { TfiLineDouble } from "react-icons/tfi";

type menuProps = {
    openMenu: () => void;
}

const Menu: React.FC<menuProps> = ({openMenu}) => {
    return (
        <>
            <div className="closeMenu" onClick={openMenu}>
                <IoClose />
            </div>                
            <ul onClick={openMenu}>
                <li><NavLink to="/">Home</NavLink></li>
                <li><a href="/sobre-nos/">Quem Somos</a></li>
                <li><NavLink to={"/portfolio/"}>Portfólio</NavLink></li>
                <li>
                    <NavLink to="/servicos/">Serviços</NavLink>
                    <ul>
                        <li>
                            <NavLink to="/servicos/estrategias/"><RxDividerHorizontal/> Estratégias</NavLink>
                            <ul>
                                <li><NavLink to="/servicos/estrategias/assessorias/"><TfiLineDouble />Assessorias</NavLink></li>
                                <li><NavLink to="/servicos/estrategias/consultoria-de-vendas-online/"><TfiLineDouble />Consultoria</NavLink></li>
                                <li><NavLink to="/servicos/estrategias/estrategias-de-marketing/"><TfiLineDouble />Estratégias de Marketing</NavLink></li>
                                <li><NavLink to="/servicos/estrategias/pesquisa/"><TfiLineDouble />Pesquisa de Mercado</NavLink></li>
                                <li><NavLink to="/servicos/estrategias/planejamento/"><TfiLineDouble />Planejamento de Mídia</NavLink></li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to="/servicos/online/"><RxDividerHorizontal/> Online</NavLink>
                            <ul>
                                <li><NavLink to="/servicos/online/marketing-digital/anuncios-patrocinados/"><TfiLineDouble />Anúncios patrocinados</NavLink></li>
                                <li><NavLink to="/servicos/online/marketing-digital/automacao-de-marketing/"><TfiLineDouble />Automação de marketing</NavLink></li>
                                <li><NavLink to="/servicos/online/marketing-digital/midias-sociais/"><TfiLineDouble />Mídias sociais</NavLink></li>
                                <li><NavLink to="/servicos/online/marketing-de-conteudo/"><TfiLineDouble />Marketing de Conteúdo</NavLink></li>
                                <li><NavLink to="/servicos/online/marketing-digital/criacao-de-sites/"><TfiLineDouble />Criação de sites</NavLink></li>
                                <li><NavLink to="/servicos/online/marketing-digital/seo-otimizacao/"><TfiLineDouble />SEO - Otimização</NavLink></li>
                            </ul>
                        </li>
                        <li>
                            <NavLink to="/servicos/offline/"><RxDividerHorizontal/> Offline</NavLink>
                            <ul>
                                <li><NavLink to="/servicos/offline/videos/"><TfiLineDouble />Vídeos e animações</NavLink></li>
                                <li><NavLink to="/servicos/offline/ilustracoes-2d-e-3d/"><TfiLineDouble />Ilustração 2D e 3D</NavLink></li>
                                <li><NavLink to="/servicos/offline/placas-e-fachadas/"><TfiLineDouble />Placas e fachadas</NavLink></li>
                                <li><NavLink to="/servicos/offline/fotografia-publicitaria/"><TfiLineDouble />Fotografia Publicitária</NavLink></li>
                                <li><NavLink to="/servicos/offline/midia-e-veiculo/impressa/"><TfiLineDouble />Mídia impressa</NavLink></li>
                                <li><NavLink to="/servicos/offline/design/"><TfiLineDouble />Design de Produtos</NavLink></li>
                            </ul>
                        </li>
                    </ul>
                </li>
                <li><NavLink to="/blog/">Artigos</NavLink></li>
                <li><NavLink to="/materiais/">Materiais</NavLink></li>
                <li><NavLink to="/contato/">Contato</NavLink></li>
              </ul>
         </>
    );
}

export default Menu;