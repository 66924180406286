import { useEffect } from "react";
import Breadcrumbs from "../layouts/Breadcrumbs";

const PoliticaPrivacidade = () => {

    useEffect(() => {
        document.title = "Política de Privacidade | Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Esta Política de Privacidade fornece informações sobre como processamos os dados pessoais por meio dos sites www.boaimagem.org, www.boaimagem.com.br, www.boaimagem.tv, www.boaimagem.net.br, www.boaimagem.tv, www.boaimagem.net, www.biplus.com.br, http://bigood.com.br/, www.bipromo.com, assim como www.appolis.com.br, http://protectdata.com.br/, http://protectmydata.com.br/."
          );
        }
    }, []);
    
    const breadcrumbsItens = [{
        name: 'Política de Privacidade',
        alias: '/politica-de-privacidade'
    }]

    return (
        <>
        <Breadcrumbs title='Política de Privacidade' items={breadcrumbsItens}/>
        <section id="PoliticaPrivacidadePage">
            <div className='relative bg_secundary section_padding_clip text_justify'>
                
                <div className='container'>
                    <span className="mb-30">Última atualização: 12 de junho de 2023</span>

                    <h2 className="mt-30">A. INTRODUÇÃO</h2>

                    <p>Este site é operado pela BOA IMAGEM PUBLICIDADE E PROPAGANDA  LTDA EPP, uma empresa organizada sob as leis do território Brasileiro, Distrito Federal, e suas afiliadas e produtos (coletivamente, "Boa Imagem®"), marca devidamente registrada no INPI, com endereço registrado em Brasília SCN, Quadra 02, Edifício Corporate 5th andar, Brasília - DF,  Brasil. A ("Boa Imagem", "Nós",  ou "a Agência") tem o compromisso de manter suas informações, protegê-las e gerenciá-las de acordo com nossas responsabilidades legais e sob as leis de privacidade e proteção de dados aplicáveis.</p>

                    <p>Esta Política de Privacidade fornece informações sobre como processamos os dados pessoais por meio dos sites <a href="http://www.boaimagem.org" target="_blank" rel="noopener noreferrer">www.boaimagem.org</a>, <a href="http://www.boaimagem.com.br"target="_blank" rel="noopener noreferrer">www.boaimagem.com.br</a>, <a href="http://www.boaimagem.tv" target="_blank" rel="noopener noreferrer">www.boaimagem.tv</a>, <a href="http://www.boaimagem.net.br" target="_blank" rel="noopener noreferrer">www.boaimagem.net.br</a>, <a href="http://www.boaimagem.tv" target="_blank" rel="noopener noreferrer">www.boaimagem.tv</a>, <a href="http://www.boaimagem.net" target="_blank" rel="noopener noreferrer">www.boaimagem.net</a>, <a href="http://www.biplus.com.br" target="_blank" rel="noopener noreferrer">www.biplus.com.br</a>, <a href="http://bigood.com.br/" target="_blank" rel="noopener noreferrer">http://bigood.com.br/</a>, <a href="http://www.bipromo.com" target="_blank" rel="noopener noreferrer">www.bipromo.com</a>, assim como <a href="http://www.appolis.com.br" target="_blank" rel="noopener noreferrer">www.appolis.com.br</a>, <a href="http://protectdata.com.br/" target="_blank" rel="noopener noreferrer">http://protectdata.com.br/</a>, <a href="http://protectmydata.com.br/" target="_blank" rel="noopener noreferrer">http://protectmydata.com.br/</a>, ("Site"), nossos aplicativos móveis e nossos produtos e serviços www.facebook.com , www.google.com , www.bitrix24.com , aplicações hospedadas na www.cloudez.io, www.dpomax.com.br e similares.</p>

                    <h3>Ao ler esta Política de Privacidade, você será informado sobre o seguinte:</h3>

                    <ol>
                    <li>Quais informações de identificação pessoal suas ou de terceiros são coletadas de você por meio do Site, bem como por meio dos Produtos e Serviços;</li>
                    <li>A organização que coleta as informações;</li>
                    <li>Como as informações são e serão utilizadas e divulgadas;</li>
                    <li>Com quem as informações podem ser compartilhadas;</li>
                    <li>Quais opções estão disponíveis para você em relação à coleta, uso e distribuição das informações e seu direito;</li>
                    <li>O tipo de procedimentos de segurança que estão em vigor para proteger a perda, uso indevido ou alteração de informações sob o controle da Boa Imagem; e</li>
                    <li>Como você pode acessar e corrigir quaisquer imprecisões nas informações coletadas sobre você.</li>
                    </ol>

                    <p>A POLÍTICA DE PRIVACIDADE DA BOA IMAGEM É INCORPORADA AOS TERMOS DE SERVIÇO DE PARACEIROS BOA IMAGEM COMO O Bitrix24 ( WWW.Bitrix24.COM/TERMS/ ), Google (WWW.GOOGLE.COM), DPOMAX (https://dpomax.com.br) E Cloudez (WWW.CLOUDEZ.IO). POR FAVOR LEIA ESSES DOCUMENTOS CUIDADOSAMENTE. CONTÉM INFORMAÇÕES IMPORTANTES QUE VOCÊ DEVE SABER ANTES DE CONTINUAR A USAR NOSSO SITE OU SERVIÇOS. AO CONTINUAR A USAR O SITE E OS SERVIÇOS DA BOA IMAGEM, VOCÊ RECONHECE QUE LEU E ENTENDEU ESTA POLÍTICA DE PRIVACIDADE.</p>

                    <p>Seu uso do site Boa Imagem e dos produtos e serviços fornecidos aqui, incluindo qualquer disputa relativa à privacidade, está sujeito a esta declaração de privacidade ("Política de Privacidade") e aos Termos de Serviço da Boa Imagem e Acordo de Processamento de Dados Bitrix24 ( https://www.Bitrix24.eu/upload/DPA/BitrixDPA.pdf ). Ao visitar o site Boa Imagem ou usar qualquer um de seus produtos e serviços (definidos abaixo), você concorda que suas informações pessoais sejam usadas e divulgadas da maneira estabelecida nesta Política e nos Termos de Serviço da Boa Imagem.</p>

                    <p>Ao marcar a caixa correspondente, utilizar nossos websites ou fornecer informações pessoais, você reconhece e confirma que leu e compreendeu os termos e condições desta Política de Privacidade. Com isso, concorda com a coleta, uso, processamento, transferência e divulgação de suas informações pessoais conforme descrito nesta Política de Privacidade. É importante que você entenda plenamente como suas informações pessoais serão utilizadas e as medidas de proteção adotadas para garantir a segurança de seus dados. Portanto, recomendamos que leia atentamente a Política de Privacidade antes de prosseguir com o uso de nossos serviços. Caso não concorde com os termos aqui apresentados, por favor, não utilize nossos websites ou forneça suas informações pessoais.</p>

                    <h3>Mudanças na Política de Privacidade</h3>

                    <p>Entendemos a importância da privacidade de suas informações pessoais e, por isso, nos reservamos o direito de atualizar esta Política de Privacidade periodicamente para garantir que esteja sempre alinhada às melhores práticas de segurança e privacidade. Recomendamos que você reveja esta política regularmente para estar ciente de quaisquer alterações realizadas. Caso não concorde com os termos atualizados, por favor, interrompa imediatamente o uso deste site. Seu uso contínuo de nossos serviços após a publicação de quaisquer alterações nesta Política de Privacidade será considerado como aceitação dessas mudanças. </p>

                    <h2>B. INFORMAÇÕES COLETADAS</h2>

                    <h3>Informações de identificação pessoal que coletamos</h3>

                    <p>A maioria dos Produtos e Serviços oferecidos aos visitantes do Site exigem que você se registre para uma conta de usuário na Boa Imagem ("Conta de Usuário"). Ao se registrar para uma conta de usuário, a Boa Imagem solicitará que você forneça voluntariamente à Boa Imagem certas informações sobre você, preenchendo e enviando um formulário on-line. Tais informações podem incluir seu nome, número de telefone, cartão de crédito ou outras informações de cobrança, endereço de e-mail e endereços postais residenciais e comerciais. As informações anteriores são coletadas e processadas por serviços de pagamento on-line, incluindo, entre outros, serviços de contas comerciais, que podemos utilizar para processamento de pagamentos, incluindo renovações automáticas de assinaturas. É totalmente opcional para você se registrar para uma conta de usuário ou se envolver em atividades que exijam uma conta de usuário.</p>

                    <p>Podemos coletar, usar, armazenar e transferir diferentes tipos de dados pessoais sobre você, que agrupamos da seguinte forma:</p>

                    <ul>
                    <li>Dados de identidade incluem nome, sobrenome, nome de usuário ou identificador semelhante, estado civil, cargo. Em alguns casos, podemos solicitar que você forneça seus documentos de identidade.</li>
                    <li>Dados de contato incluem endereço de cobrança, endereço de e-mail e números de telefone.</li>
                    <li>Dados de transação incluem detalhes sobre pagamentos de e para você e outros detalhes de produtos e serviços que você comprou de nós.</li>
                    <li>Dados técnicos incluem endereço de protocolo de internet (IP), seus dados de login, tipo e versão do navegador, configuração e localização do fuso horário, tipos e versões de plug-in do navegador, sistema operacional e plataforma e outras tecnologias nos dispositivos que você usa para acessar este site. Alguns destes dados são coletados para aprimorar a experiência do usuário em nossos sites.</li>
                    <li>Dados de perfil incluem seu nome de usuário e senha, compras ou pedidos feitos por você, suas preferências, feedback e respostas a pesquisas. Estes dados são coletados para garantir a segurança de suas transações realizadas em nossos sites.</li>
                    <li>Os Dados de Uso incluem informações sobre como você usa nosso site, produtos e serviços. Estes dados são coletados para identificarmos como você utiliza nossos serviços, nos ajudando a corrigir problemas e a desenvolver novos recursos e produtos que atendam às necessidades de nossos clientes.</li>
                    <li>Dados de marketing e comunicações incluem suas preferências em receber marketing nosso e de terceiros e suas preferências de comunicação. Ao coletar estes dados, entendemos melhor o comportamento e preferências dos usuários, permitindo a personalização de campanhas de marketing. Assim, fornecemos uma experiência de anúncios que seja mais adequada ao que você procura.</li>
                    </ul>

                    <p>Você não é obrigado a responder a nenhuma pergunta e pode recusar fornecer suas informações a qualquer momento. No entanto, é importante lembrar que caso opte por não fornecer as informações, isso pode impedir que você tenha acesso a algus dos nossos produtos ou serviços.</p>

                    <p>Para algumas atividades, solicitamos informações obrigatórias e outras opcionais. Caso você não forneça as que são consideradas obrigatórias para uma determinada atividade ou compra, não será possível participar dela ou concluí-la. Mas não se preocupe, a Boa Imagem sempre informará claramente quais informações são obrigatórias e quais são opcionais, para que você possa tomar uma decisão consciente.</p>

                    <p><b>Você pode ter a oportunidade de assinar um boletim informativo periódico e atualizações de produtos.</b> Esta newsletter permite à Boa Imagem informá-lo sobre novos Produtos e Serviços, atualizações, bem como outras notícias relevantes para a empresa. O serviço de assinatura exige que você forneça seu nome e sobrenome, seu endereço de e-mail e outras informações. Opcionalmente, você será solicitado a fornecer o nome da empresa que representa.</p>

                    <h3>Navegação em sites e interações com clientes de e-mail</h3>

                    <p>Quando você acessa uma página do nosso site ou clica em um link de URL incluído em nossos e-mails, nossos servidores registram as informações do cabeçalho da solicitação HTTP, JavaScript ou outras ferramentas técnicas semelhantes. Essas informações incluem o número do IP, a hora da solicitação, a URL solicitada e outras informações relevantes.</p>

                    <p>Essas informações são coletadas com o objetivo de garantir o funcionamento adequado do site e fornecer a funcionalidade que você espera encontrar nele. Além disso, usamos essas informações para obter uma melhor compreensão de como os visitantes interagem com o nosso site, de modo que possamos ajustar o seu conteúdo e funcionalidades para atender às necessidades dos nossos usuários da melhor forma possível.</p>

                    <p>Nossa coleta de dados é realizada com o objetivo de melhorar a experiência do usuário, garantindo a funcionalidade e eficiência do site, bem como tornando-o mais adequado às necessidades de nossos usuários.</p>

                    <h3>Aplicativos móveis</h3>

                    <p>Quando você baixa, acessa e usa o nosso App, ele pode usar a tecnologia para coletar automaticamente:</p>

                    <p>Seus detalhes de uso: Quando você acessa e usa o App, podemos coletar automaticamente certos detalhes de seu acesso e uso do App, incluindo dados de tráfego, logs e outros dados de comunicação e os recursos que você acessa e usa no ou por meio do App.</p>

                    <p>Informação de dispositivo: Podemos coletar informações sobre seu dispositivo móvel e conexão com a Internet, incluindo o endereço IP, sistema operacional, tipo de navegador, informações de rede móvel e o número de telefone do dispositivo.</p>

                    <p>Informações de localização: Quando você instrui a Boa Imagem a coletar a localização de seus funcionários e está sujeito ao consentimento explícito deles, este aplicativo pode coletar informações em tempo real sobre a localização do seu dispositivo.</p>

                    <h3>Arquivos</h3>

                    <p>A Boa Imagem coleta e armazena os arquivos que você carrega, baixa ou acessa através dos Produtos e Serviços oferecidos através do site ou serviço. Mas entendemos que esses arquivos podem ser confidenciais ou conter informações confidenciais. Consequentemente, a Boa Imagem não compartilha ou permite que terceiros acessem tais arquivos. No entanto, nenhum sistema de armazenamento e recuperação de dados protegido por senha pode ser totalmente impenetrável. Embora nossos parceiros utilizem várias tecnologias e procedimentos de segurança para garantir que os dados no site estejam seguros, apesar dos esforços da Boa Imagem, pode ser possível que terceiros não autorizados acessem, visualizem, copiem, modifiquem e distribuam os dados e arquivos que você armazena conosco por meio do Site, Produtos e Serviços.</p>

                    <h3>Sua conta de usuário e perfil público</h3>

                    <p>Conforme descrito acima, você terá a oportunidade de criar uma Conta de Usuário contendo suas informações pessoais. Uma conta de usuário é necessária para usar a maioria dos produtos e serviços oferecidos.</p>

                    <h3>Seu perfil público</h3>

                    <p>Depois que a Conta de Usuário for criada, você poderá fornecer informações adicionais ao seu perfil, acessar, revisar e atualizar as informações da sua conta on-line por meio do Site.</p>

                    <p>Fornecer informações adicionais sobre você além do que é minimamente necessário para a criação da conta depende inteiramente de você. Qualquer informação que você fornecer no registro ou em sua seção de perfil pode ser usada por nós conforme descrito em nossos Termos de Uso e nesta Política de Privacidade. Você pode revisar as informações pessoais que nos fornece e fazer as alterações desejadas nas informações que publica a qualquer momento, fazendo login em sua conta no site (caso exista algum tipo de conta em nossos sistemas).</p>

                    <p>Se você deseja desativar sua conta de usuário, pode fazê-lo através da interface da conta de usuário ou entrando em contato com Boa Imagem em contato@protectada.com.br . Quando você desativa sua conta de usuário, todas as informações armazenadas e mantidas como parte de sua conta serão excluídas de nossos servidores, incluindo os arquivos que você armazenou por meio do site ou de seus produtos e serviços.</p>

                    <h3>Informações relativas a crianças</h3>

                    <p>Não coletamos intencionalmente informações pessoais de crianças menores de 16 anos sem o consentimento do titular da responsabilidade parental da criança. Você deve ter 16 anos ou a maioridade em sua jurisdição para usar nosso site, produtos ou serviços. Se você tem menos de 16 anos, você só pode usar nosso site com a permissão de seus pais ou responsável legal. Os pais e responsáveis legais podem solicitar que revisemos, excluamos ou interrompamos a coleta das informações de identificação pessoal de seus filhos entrando em contato conosco por carta (usando as informações de endereço fornecidas na parte superior desta página) ou e-mail para contato@protectada.com.br .</p>

                    <h3>Protegendo a transmissão e o armazenamento de informações</h3>

                    <p>A Boa Imagem está comprometida em proteger a segurança de suas informações pessoais. Usamos uma variedade de tecnologias e procedimentos de segurança para ajudar a proteger suas informações pessoais.</p>
                    <p>Em particular, envidamos todos os esforços razoáveis para garantir a implementação de medidas de segurança adequadas para proteger os seus Dados Pessoais. Aplicamos as medidas de segurança previstas nos regulamentos aplicáveis, bem como as nossas próprias medidas de segurança técnicas e organizacionais, incluindo política, governança, procedimentos, responsabilidades, avaliação de riscos, segurança de rede e subprocessamento.</p>
                    <p>Boa Imagem opera redes de dados seguras protegidas por firewall padrão da indústria e sistemas de proteção por senha. Nossas políticas de segurança e privacidade são revisadas periodicamente e aprimoradas conforme necessário, e somente indivíduos autorizados têm acesso às informações fornecidas por nossos usuários. A Boa Imagem toma medidas para garantir que suas informações sejam tratadas com segurança e de acordo com esta Política de Privacidade.</p>
                    <p>Infelizmente, a transmissão de informações via internet não é totalmente segura e por isso não podemos garantir a segurança dos seus Dados Pessoais transmitidos através do Site; qualquer transmissão é por sua conta e risco. Assim que recebermos suas informações, usaremos procedimentos rígidos e recursos de segurança para tentar impedir o acesso não autorizado.</p>
                    <p>Tratamos das informações que você nos fornece, incluindo os Arquivos, como informações confidenciais; está, portanto, sujeito aos procedimentos de segurança e às políticas corporativas de nossa empresa em relação à proteção e uso de informações confidenciais. Depois que as informações de identificação pessoal chegam a Boa Imagem, elas são armazenadas em um servidor com recursos de segurança física e eletrônica, como de costume no setor, incluindo a utilização de procedimentos de login/senha e firewalls eletrônicos projetados para bloquear o acesso não autorizado de fora da Boa Imagem. Como as leis aplicáveis às informações pessoais variam de acordo com o país, nossos escritórios ou outras operações comerciais podem implementar medidas adicionais que variam de acordo com os requisitos legais aplicáveis. As informações coletadas nos sites cobertos por esta Política de Privacidade são processadas e armazenadas nos Estados Unidos e possivelmente em outras jurisdições e também em outros países onde a Boa Imagem e seus provedores de serviços conduzem negócios. Todos os funcionários da Boa Imagem são obrigados por nossas políticas de privacidade e segurança. Suas informações são acessíveis apenas aos funcionários que realizam suporte técnico do serviço.</p>
                    <p>Se uma senha for usada para ajudar a proteger suas contas e informações pessoais, é sua responsabilidade manter sua senha confidencial. Não compartilhe esta informação com ninguém. Se você estiver compartilhando um computador com alguém, sempre deve sair antes de sair de qualquer site ou serviço para proteger o acesso às suas informações de usuários subsequentes.</p>

                    <h2>C. USO E DIVULGAÇÃO DE INFORMAÇÕES</h2>

                    <h3>Como suas informações pessoais são usadas</h3>

                    <h3>Com base legal no contrato</h3>

                    <p>Se você assinar um serviço ou registrar um produto, por exemplo, tais informações são utilizadas para registrar seus direitos, se houver, a suporte técnico ou outros benefícios que possam ser disponibilizados aos usuários cadastrados. Também usamos suas informações pessoais para registrá-lo em uma conta ou em um webinar, enviar um e-mail de confirmação, responder às suas solicitações e ajudar a processar a compra ou serviço que você selecionou, comunicar-se com você quando você entrar em contato com um consultor de vendas ou parceiro autorizado, processando, cumprindo e acompanhando as transações de nossos produtos e serviços, suporte e treinamento, para processar qualquer solicitação sua de informações ou conselhos, para gerenciar sua conta e conteúdo que você carrega, para lidar com consultas, reclamações e feedback de você e de nossos parceiros.</p>

                    <h3>Com base em nossos interesses legítimos</h3>

                    <p>Boa Imagem pode usar dados estatísticos para análise estatística, marketing ou fins promocionais semelhantes para ajudar a melhorar nossas ofertas para você, para ajudar a diagnosticar quaisquer problemas com nosso servidor e administrar o site ou para melhorar sua experiência de navegação personalizando o site.</p>

                    <p>Reservamo-nos o direito de enviar um único e-mail de confirmação de registro e mensagens de alerta de serviço pouco frequentes aos usuários para informá-los sobre alterações específicas que possam afetar sua capacidade de usar um serviço para o qual se inscreveram anteriormente, independentemente da opção de contato por e-mail.</p>

                    <h3>Com base em nossos interesses legítimos e seu consentimento</h3>

                    <p>Usamos suas informações pessoais para desenvolver e conduzir nossos negócios. Alguns exemplos de como usamos informações pessoais incluem:</p>
                    <p><b>Melhorar nossos produtos e serviços; personalizar comunicações com você; realização de pesquisas de mercado; realização de pesquisas com clientes; envolver-se em publicidade mais direcionada e específica para o cliente; em processamento.</b></p>
                    <p>A Boa Imagem coleta suas informações para registrar e apoiar sua participação nas atividades que você selecionar.</p>
                    <p>Com base no seu consentimento, suas informações pessoais também podem ser usadas para mantê-lo informado sobre novos produtos, atualizações de produtos, patches, ofertas especiais e outros produtos e serviços da Boa Imagem e de terceiros selecionados, caso você tenha consentido em receber tais comunicações.</p>
                    <p>Quando você cria uma Conta de Usuário em sistemas da Boa Imagem e/ou fornece seu endereço de e-mail e outras informações de identificação pessoal, com base em suas preferências pessoais, podemos contatá-lo periodicamente por e-mail e fornecer informações sobre ofertas especiais e promoções que podem ser de interesse para você. Essas comunicações estarão relacionadas às ofertas da Boa Imagem e/ou promoções de terceiros selecionados e respeitáveis com os quais a Boa Imagem tem um relacionamento estratégico de marketing porque oferecem produtos ou serviços que acreditamos ser de seu interesse. Podemos usar um provedor de serviços de e-mail terceirizado para enviar e-mails para você. Este provedor de serviços está proibido de usar seu endereço de e-mail para qualquer finalidade que não seja enviar e-mail relacionado a Boa Imagem. Se você não deseja receber e-mails com atualizações e informações, contato@protectdata.com.br. Além disso, toda vez que você receber um e-mail, você terá a opção de cancelar futuros e-mails seguindo as instruções fornecidas no e-mail.</p>

                    <h3>Obrigação legal</h3>

                    <p>Podemos reter algumas de suas informações para cumprir requisitos legais e fazer cumprir os termos de uso de nosso site ou proteger nossos direitos legais.</p>
                    <p>Também nos reservamos o direito de entrar em contato com você se formos obrigados a fazê-lo como parte de um processo legal ou se houver violação de qualquer licenciamento, garantia e contratos de compra aplicáveis.</p>
                    <p>A Boa Imagem retém esses direitos porque, em alguns casos limitados, sentimos que podemos precisar entrar em contato com você por questões legais ou em relação a assuntos que sejam importantes para você. Esses direitos não nos permitem entrar em contato com você para comercializar um serviço novo ou existente se você nos pediu para não fazê-lo, e a emissão desses tipos de comunicações é rara.</p>

                    <h3>Divulgação dos seus dados pessoais a Terceiros</h3>

                    <p>A Boa Imagem usa serviços de terceiros, como provedores de serviços de e-mail e provedores de hospedagem que atuam como contratados independentes em nome da Boa Imagem. Não compartilharemos suas informações com terceiros para fins de marketing.</p>
                    <p>Temos contratos em vigor com nossos processadores de dados. Isso significa que eles não podem fazer nada com suas informações pessoais, a menos que os instruímos a fazê-lo. Eles não compartilharão suas informações pessoais com nenhuma organização além de nós. Eles irão segurá-lo com segurança e retê-lo pelo período que instruirmos.</p>
                    <p>O processamento de dados que consiste em serviços de provedor de hospedagem (armazenamento e manutenção de bancos de dados contendo seus dados pessoais, protegidos por senha) é realizado em nosso nome e sob acordos de confidencialidade por escrito por nosso processador de dados autorizado Amazon Web Services, ("AWS"), Frankfurt, Alemanha. Como a Amazon Web Services (“AWS”) faz parte de uma organização global com empresas operacionais locais em vários países do mundo.</p>
                    <p>A Boa Imagem pode divulgar suas informações de identificação pessoal se acreditarmos de boa fé que somos obrigados a fazê-lo para cumprir a lei aplicável, uma intimação ou outro processo legal. A Boa Imagem também pode divulgar informações de identificação pessoal quando tivermos motivos para acreditar que a divulgação dessas informações é necessária para identificar, contatar ou mover uma ação legal contra alguém que possa estar violando esta Política de Privacidade, os termos de seus acordos com a Empresa (como os Termos do Serviço) ou para proteger a segurança de nossos usuários e do público ou sob acordos de confidencialidade e licenciamento com terceiros que nos ajudam a desenvolver, operar e manter o site e seu conteúdo.</p>
                    <p>Boa Imagem pode ser solicitado pela aplicação da lei ou autoridades judiciais a fornecer informações de identificação pessoal às autoridades governamentais apropriadas. Se solicitado pela aplicação da lei ou autoridades judiciais, a Boa Imagem fornecerá essas informações mediante o recebimento da documentação apropriada. Boa Imagem também pode fornecer informações para aplicação da lei para proteger sua propriedade e a saúde e segurança dos indivíduos conforme permitido por lei.</p>
                    <p>Caso todas ou substancialmente todas as ações da Boa Imagem e/ou todos ou substancialmente todos os ativos sejam transferidos ou vendidos para outra entidade, a Boa Imagem pode transferir informações de identificação pessoal para a entidade adquirente. Se, como resultado dessa transição de negócios, suas informações de identificação pessoal forem usadas de maneira materialmente diferente, você terá uma escolha consistente com nossa política em relação à notificação de alterações.</p>

                    <h3>Onde armazenamos os seus dados pessoais</h3>

                    <p>A geografia do armazenamento de dados dependendo da zona de domínio Boa Imagem é descrita na seção Infraestrutura Boa Imagem, Subprocessadores e controladores conjuntos.</p>
                    <p>Os Dados Pessoais obtidos através das zonas de domínio Boa Imagem são totalmente compatíveis com GDPR.</p>
                    <p>Boa Imagem pode processar Dados Pessoais do Usuário fora da área da União Europeia, sujeito às salvaguardas apropriadas sob o artigo 46 GDPR (especificamente pelas cláusulas padrão de proteção de dados adotadas pela Comissão Europeia de acordo com o procedimento de exame).</p>

                    <h2>D. SUAS ESCOLHAS, DIREITOS E QUESTÕES E QUEIXAS RELACIONADAS À PRIVACIDADE</h2>

                    <h3>Opções disponíveis para você</h3>

                    <p>Você sempre pode optar por divulgar ou não informações de identificação pessoal e essa escolha não o impedirá de usar o site. Observe, no entanto, se você optar por reter as informações solicitadas, talvez não possamos fornecer alguns dos serviços dependentes da coleta dessas informações e você terá a oportunidade de "aceitar" e fazer sua opções de preferência para quaisquer itens que sejam opcionais e que não sejam pré-requisitos para nossa prestação de tais serviços.</p>

                    <p>Você pode optar, a qualquer momento, por não receber e-mails da Boa Imagem ou por não ter suas informações de identificação pessoal compartilhadas com empresas selecionadas. Se você deseja cancelar, pode fazê-lo editando suas preferências de conta de usuário com relação a e-mail ou compartilhamento de informações. Se você optar por "excluir", não iremos, conforme aplicável, compartilhar suas informações pessoais com terceiros ou enviar e-mails. No entanto, podemos continuar a usar suas informações pessoais para fins internos, para aprimorar sua experiência de usuário ou conforme necessário para administrar o site ou cumprir a lei aplicável.</p>

                    <h3>Direitos de privacidade da Califórnia</h3>

                    <p>Não compartilharemos quaisquer Dados Pessoais com terceiros para fins de marketing direto na medida proibida pela lei da Califórnia. Se nossas práticas mudarem, faremos isso de acordo com as leis aplicáveis e notificaremos você com antecedência.</p>
                    <p>Na União Europeia e em algumas outras jurisdições, você pode ter os seguintes direitos em relação ao processamento de seus dados pessoais:</p>
                    <p>Direito de acesso. Você tem o direito de nos solicitar cópias de suas informações pessoais. Este direito sempre se aplica;</p>
                    <p>Direito à retificação. Você tem o direito de nos pedir para retificar as informações que considera imprecisas. Você também tem o direito de nos pedir para completar as informações que considera incompletas. Este direito sempre se aplica;</p>
                    <p>Direito ao apagamento. Este direito é aplicável quando se verifica uma das seguintes situações:</p>
                    <p>(a) Os dados pessoais já não são necessários relativamente às finalidades para as quais foram recolhidos ou de outra forma tratados;</p>
                    <p>(b) O titular dos dados retirar o consentimento no qual o tratamento se baseia ou quando não houver outro fundamento legal para o tratamento;</p>
                    <p>(c) O titular dos dados se opõe ao processamento e não há motivos legítimos imperiosos para o processamento;</p>
                    <p>(d) Os dados pessoais foram processados ilegalmente;</p>
                    <p>Direito à restrição do processamento. Quando o processamento for restrito, armazenaremos seus dados pessoais, mas não os processaremos posteriormente;</p>
                    <p>Direito à portabilidade de dados. É seu direito receber os dados pessoais que você nos forneceu, em um formato estruturado, comumente usado e legível por máquina e o direito de transmitir esses dados para outro controlador do controlador atual aplicável se: O processamento for baseado em consentimento ou mediante contrato, sendo o tratamento realizado por meios automatizados;</p>
                    <p>Direito de objeção. Você tem o direito de se opor ao processamento (por motivos relacionados à sua situação particular) se processarmos suas informações em nossos interesses legítimos. As únicas razões pelas quais poderemos negar sua solicitação são se pudermos mostrar motivos legítimos e convincentes para o processamento, que se sobrepõem aos seus interesses, direitos e liberdades, ou o processamento é para o estabelecimento, exercício ou defesa de uma reivindicação legal ou se o processamento de dados é necessário para a execução de nosso contrato com você;</p>
                    <p>Direito de retirar seu consentimento a qualquer momento no caso de qualquer processamento baseado em consentimento de seus dados pessoais sem afetar a legalidade do processamento baseado em consentimento antes de sua retirada; e</p>

                    <h3>Direito de apresentar reclamação a uma autoridade supervisora.</h3>

                    <p>Você pode exercer seus direitos legais entrando em contato conosco por e-mail para o Encarregado de Dados contato@protectdata.com.br. Esta solicitação deve incluir pelo menos as seguintes informações: o link para seu portal e seu endereço de e-mail de registro de administrador.</p>
                    <p>Boa Imagem leva e aborda as preocupações de privacidade de seus usuários com o máximo respeito e atenção. Se você acredita que houve um caso de não conformidade com esta Política de Privacidade em relação às suas informações pessoais ou se tiver outras dúvidas ou preocupações relacionadas, você pode escrever ou entrar em contato com a Boa Imagem no e-mail para o Encarregado de Dados contato@protectdata.com.br.</p>
                    <p>Em sua mensagem, descreva o mais detalhadamente possível a natureza de sua consulta ou as formas pelas quais você acredita que a Política de Privacidade não foi cumprida. Investigaremos sua consulta ou reclamação imediatamente.</p>
                    <p>Observe que se você fornecer a Boa Imagem preferências de privacidade inconsistentes (por exemplo, indicando em uma ocasião que terceiros podem contatá-lo com ofertas de marketing e indicando em outra ocasião que não podem), a Boa Imagem não pode garantir que sua preferência de privacidade mais recente seja honrada.</p>

                    <h3>Cookies e pixels de rastreamento</h3>

                    <p>Nosso site usa cookies para diferenciá-lo de outros usuários do nosso site. Isso nos ajuda a fornecer uma boa experiência ao navegar em nosso site e também nos permite melhorar a qualidade dos nossos serviços.</p>
                    <p>Um cookie é um pequeno arquivo de letras e números que armazenamos no seu navegador ou no disco rígido do seu computador, se você concordar. Os cookies contêm informações que são transferidas para o disco rígido do seu computador.</p>
                    <p>Usamos os seguintes cookies:</p>

                    <ul>
                    <li>Cookies estritamente necessários. Estes são cookies necessários para o funcionamento do nosso site. Eles incluem, por exemplo, cookies que permitem que você faça login em áreas seguras do nosso site, use um carrinho de compras ou faça uso de serviços de faturamento eletrônico.</li>
                    <li>Cookies analíticos ou de desempenho. Isso nos permite reconhecer e contar o número de visitantes e ver como os visitantes se movem em nosso site quando o estão usando. Isso nos ajuda a melhorar a maneira como nosso site funciona, por exemplo, garantindo que os usuários encontrem o que procuram com facilidade.</li>
                    <li>Cookies de funcionalidade. Estes são usados para reconhecê-lo quando você retornar ao nosso site. Isso nos permite personalizar nosso conteúdo para você, cumprimentá-lo pelo nome e lembrar suas preferências (por exemplo, sua escolha de idioma ou região).</li>
                    </ul>

                    <h3>Opções sobre o uso de cookies</h3>

                    <p>Você pode cancelar qualquer instalação de cookies não estritamente necessária em sua primeira visita aos nossos sites.</p>
                    <p>Além disso, você pode configurar seu navegador para recusar todos ou alguns cookies do navegador ou para alertá-lo quando os cookies estiverem sendo enviados. Se você desativar ou recusar cookies, observe que algumas partes deste site podem ficar inacessíveis ou não funcionar corretamente.</p>
                    <p>Você pode configurar suas preferências de privacidade nas configurações de privacidade da sua conta do Facebook .</p>
                    <p>Você pode optar por não ter a atividade do seu site disponível para o Google Analytics instalando o complemento do navegador para desativação do Google Analytics :</p>
                    <p>Você pode configurar seu navegador para bloquear cookies (consulte as instruções de seu navegador específico sobre como fazer isso), embora isso possa afetar adversamente sua capacidade de realizar determinadas transações, usar determinadas funcionalidades e acessar determinados conteúdos em nosso site.</p>
                    <p>Integrações Boa Imagem, Links de Terceiros e Extensões Declaração de Privacidade</p>

                    <h3>1. Introdução</h3>
                    <p>Esta Declaração de Privacidade descreve como os Dados Pessoais são processados nas integrações de Terceiros Boa Imagem, links de Terceiros e Aplicativos Não-Bitrix.</p>

                    <h3>2. Definições</h3>
                    <p>2.1 “Boa Imagem,” “nós,” “nos” “nosso” significa BOA IMAGEM PROPAGANDA E PUBLICIDADE LTDA, seus produtos, softwares parceiros e suas afiliadas;</p>
                    <p>2.2 “Cliente”, “Você” significa a organização que assina os Serviços e através da qual você, o usuário final de tais Serviços, recebe acesso aos Serviços Boa Imagem;</p>
                    <p>2.3 “Dados do Cliente” significa dados eletrônicos e informações enviadas por ou para o Cliente aos Serviços;</p>
                    <p>2.4 “Dados pessoais” significa qualquer informação relativa a uma pessoa singular identificada ou identificável;</p>
                    <p>2.5 “Serviços Bitrix24” significa produtos e serviços que são solicitados por nossos clientes e disponibilizados online pelo Bitrix24 conforme descrito nos Termos de Serviço Bitrix24;</p>
                    <p>2.6 “Serviços de Terceiros” significa serviços fornecidos ao Cliente por um terceiro e/ou listados em um diretório online, catálogo ou mercado de aplicativos que interromperam com os Serviços Boa Imagem (“Aplicativos não desenvolvidos pela Boa Imagem”).</p>

                    <h3>3. Divulgação de uso limitado</h3>
                    <p>O uso de informações da Boa Imagem recebidas das APIs do Google estará em conformidade com a Política de dados do usuário dos serviços de API do Google , incluindo os requisitos de uso limitado.</p>

                    <h3>4. Quais dados são coletados?</h3>

                    <h4>4.1 Integração de serviços do Google</h4>

                    <p>Se você conectar sua conta Boa Imagem a aplicativos ou serviços fornecidos por outras partes, como o Google, alguns Dados do cliente podem ser coletados do seu dispositivo pelo Google. Esses dados podem ser coletados automaticamente por meio do uso de interfaces de programação de aplicativos, como os serviços de API do Google ou API do Gmail (OAuth), e podem incluir:</p>

                    <ul>
                    <li>O conteúdo, metadados e informações relacionadas de e-mails, eventos de calendário do Google e arquivos armazenados quando você opta por sincronizar seu aplicativo com Gmail, calendário e serviços de armazenamento em nuvem (Google Drive);</li>
                    <li>Endereços de e-mail de suas pastas Caixa de entrada e Enviados, quando você optar por sincronizar sua conta Boa Imagem e Gmail com a agenda de endereços do seu dispositivo; e</li>
                    <li>Dados do cliente que você envia de sua conta Boa Imagem para o Google, ou dados que você solicita de qualquer um dos serviços do Google, automaticamente ou de outra forma. Observe que, como precaução de segurança, se você optar por conectar sua conta Boa Imagem a aplicativos ou serviços fornecidos pelo Google e solicitar dados de qualquer um dos serviços do Google, informações que identificam você ou seu dispositivo também podem ser enviadas para autenticar o solicitar. Você pode revogar o acesso a qualquer momento.</li>
                    <li>O uso das informações recebidas pelo aplicativo e a transferência de informações do aplicativo para qualquer outro aplicativo a partir das APIs do Google obedecerão aos Requisitos de uso limitado do Google .</li>
                    <li>O uso de informações recebidas de APIs do Gmail pelo aplicativo obedecerá aos Requisitos de uso limitado do Google .</li>
                    </ul>

                    <h4>4.2 Boa Imagem Sincronização bidirecional:</h4>

                    <ul>
                    <li>A sincronização bidirecional entre a Boa Imagem e o Gmail significa que, mesmo que você trabalhe com e-mails no Gmail, você terá cópias deles na Boa Imagem CRM ou Webmail. Além disso, você pode enviar e-mails diretamente de dentro do Boa Imagem usando o Gmail quando esta integração estiver habilitada ;</li>
                    <li>Calendário A sincronização bidirecional entre o Boa Imagem e o Google Calendars significa que todos os eventos criados no Google Calendar são sincronizados automaticamente com os calendários do Boa Imagem e vice-versa;</li>
                    <li>Google Docs: Google Docs pode ser usado dentro da conta Boa Imagem para visualizar e editar documentos online.</li>
                    </ul>

                    <h4>4.3 Em certos países, por exemplo, nos sites relacionados ao Boa Imagem.com, terceiros como a Microsoft podem coletar ou receber Dados Pessoais de indivíduos que visitam Nossos sites de acordo com sua política de privacidade: https://privacy.microsoft.com/en- nós/declaração de privacidade . Para acessar e controlar essa coleta de dados, visite a página de desativação da Microsoft: https://account.microsoft.com/privacy/ad-settings/signedout</h4>

                    <h2>5. Como suas informações pessoais são usadas por meio de integrações de terceiros:</h2>

                    <p>5.1 Com base em nossos interesses legítimos ou conforme necessário para executar nosso contrato com você, para administrar, melhorar e fornecer a você nossos Serviços de integração estendida mediante sua solicitação.</p>

                    <p>5.2 Obrigação legal</p>

                    <p>Podemos reter ou solicitar algumas de suas informações para cumprir requisitos legais e aplicar os termos de uso de nosso site ou proteger nossos direitos legais.</p>

                    <p>A fim de cumprir os controles de exportação dos Estados Unidos e da União Européia e as leis e regulamentos de sanções econômicas, incluindo, entre outros, os Regulamentos de Administração de Exportação dos Estados Unidos (“EAR”), regulamentos promulgados pelo Departamento de Relações Exteriores do Departamento do Tesouro dos EUA Controle de Ativos (“OFAC”) e regulamentos promulgados pelo Conselho da União Europeia (“UE”), em certos casos Boa Imagem pode solicitar que você forneça sua documentação de identidade.</p>

                    <p>Também nos reservamos o direito de entrar em contato com você se formos obrigados a fazê-lo como parte de um processo legal ou se houver violação de qualquer licenciamento, garantia e contratos de compra, este contato tem como objetivo resguardar a Lei Geral de Proteção de Dados.</p>

                    <p>A Boa Imagem retém esses direitos porque, em casos limitados, sentimos que podemos precisar entrar em contato com você por questões legais ou em relação a assuntos que sejam importantes para você. Esses direitos não nos permitem entrar em contato com você para comercializar um serviço novo ou existente se você nos pediu para não fazê-lo, e a emissão desses tipos de comunicações é rara.</p>

                    <h3>6. Divulgação de seus dados pessoais a terceiros</h3>

                    <p>Boa Imagem não é responsável pelas práticas de processamento de dados de quaisquer provedores terceirizados. Incentivamos você a revisar os respectivos avisos de privacidade de seus provedores terceirizados antes de ativar uma integração de serviço terceirizado.</p>

                    <h3>7. Opções disponíveis para você</h3>

                    <p>Você sempre pode optar por divulgar ou não informações de identificação pessoal e essa escolha não o impedirá de usar nossos Serviços. Observe, no entanto, se você optar por não disponibilizar algumas de suas informações pessoais, talvez não possamos fornecer alguns dos serviços descritos nesta declaração de privacidade de integrações, links de terceiros e extensões.</p>

                    <h3>8. Retenção de dados</h3>

                    <p>Boa Imagem pode reter seus Dados Pessoais por um período de tempo consistente com o propósito original da coleta (consulte a seção “4. Como suas informações pessoais são usadas por meio de integrações de terceiros”).</p>

                    <h3>9. Seus direitos</h3>

                    <p>Na União Europeia e em algumas outras jurisdições, você pode ter os seguintes direitos em relação ao processamento de seus dados pessoais:</p>
                    <p>Direito de acesso. Você tem o direito de nos solicitar cópias de suas informações pessoais. Este direito sempre se aplica;</p>
                    <p>Direito à retificação. Você tem o direito de nos pedir para retificar as informações que considera imprecisas. Você também tem o direito de nos pedir para completar as informações que considera incompletas. Este direito sempre se aplica;</p>
                    <p>Direito ao apagamento. Este direito é aplicável quando um dos seguintes se aplica:</p>

                    <p>(a) os dados pessoais já não são necessários relativamente às finalidades para as quais foram recolhidos ou de outra forma tratados;</p>
                    <p>(b) O titular dos dados retirar o consentimento no qual o tratamento se baseia ou quando não houver outro fundamento legal para o tratamento;</p>
                    <p>(c) O titular dos dados se opõe ao processamento e não há motivos legítimos imperiosos para o processamento;</p>
                    <p>(d) os dados pessoais foram processados ilegalmente;</p>

                    <p>Direito à restrição do processamento. Quando o processamento for restrito, armazenaremos seus dados pessoais, mas não os processaremos posteriormente;</p>
                    <p>Direito à portabilidade de dados. É seu direito receber os dados pessoais que você nos forneceu, em um formato estruturado, comumente usado e legível por máquina e o direito de transmitir esses dados para outro controlador do controlador atual aplicável se: O processamento for baseado em consentimento ou mediante contrato, sendo o tratamento realizado por meios automatizados;</p>
                    <p>Direito de objeção. Você tem o direito de se opor ao processamento (por motivos relacionados à sua situação particular) se processarmos suas informações em nossos interesses legítimos. As únicas razões pelas quais poderemos negar sua solicitação são se pudermos mostrar motivos legítimos e convincentes para o processamento, que se sobrepõem aos seus interesses, direitos e liberdades, ou o processamento é para o estabelecimento, exercício ou defesa de uma reivindicação legal ou se o processamento de dados é necessário para a execução de nosso contrato com você;</p>
                    <p>Direito de retirar seu consentimento a qualquer momento no caso de qualquer processamento baseado em consentimento de seus dados pessoais sem afetar a legalidade do processamento baseado em consentimento antes de sua retirada; e</p>
                    <p>Direito de apresentar reclamação à Autoridade Nacional de Proteção de Dados (ANPD).</p>
                </div>

            </div>
        </section>
        </>
    );
}

export default PoliticaPrivacidade;