import Breadcrumbs from "../layouts/Breadcrumbs";
import { IoLogoWhatsapp } from "react-icons/io";
import { LuPhone } from "react-icons/lu";
import { IoIosMail } from "react-icons/io";
import { Link } from "react-router-dom";
import { useEffect } from "react";

const Contato = () => {

    useEffect(() => {
        document.title = "Telefone e E-mail de contato | Agência Boa Imagem";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Endereço, Whatsapp, Telefone e E-mail da agência Boa Imagem em Brasília. Fale com um de nossos consultores de marketing."
          );
        }
    }, []);
    
    const breadcrumbsItens = [{
        name: 'Contato',
        alias: '/contato'
    }]

    return (
        <>
        <Breadcrumbs title='Contato' items={breadcrumbsItens}/>
        <section id="contatoPage">
            <div id="contatcsInfos" className='relative bg_secundary section_padding_clip text_center'>
                
                <div className='container'>
                    <p>O Propósito da agência é ajudar outras Empresas e nosso objetivo é usar o marketing para vender, garantindo assim que as pessoas certas vejam o que você oferece, demonstrando desde o primeiro momento Boa Imagem aos seus futuros clientes.</p>
                </div>

                <div className='box_two_columns container'>
                    <a className="btn" rel="nofollow noopener" title="Mandar WhatsApp para a Boa Imagem" href="tel:6139631444">
                        <LuPhone />
                        (61) 3963-1444
                    </a>
                    <a className="btn" target="_blank" rel="nofollow noopener" title="Ligar para a Boa Imagem" href="https://wa.me/556139631444">
                        <IoLogoWhatsapp />
                        (61) 3963-1444
                    </a>
                    <a className="btn" target="_blank" rel="nofollow noopener" title="Mandar e-mail para a Boa Imagem" href="mailto:atendimento@boaimagem.tv">
                        <IoIosMail />
                        atendimento@boaimagem.tv
                    </a>
                </div>

                <div className='container'>

                </div>
            </div>

            <div id="workWithUs" className='relative bg_black section_padding_clip clip_top text_center'>
                <div className="container">
                    <h2>Faça a diferença:</h2>
                    <p>Participe da equipe<br/> multidisciplinar<br/> da Agência<br/> Boa Imagem.</p>
                    <Link to="/jobs-vagas-oportunidades" className="btn">Trabalhe conosco</Link>
                </div>
            </div>
        </section>
        </>
    );
}

export default Contato;