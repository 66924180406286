// main.tsx ou index.tsx
import React from "react";
import ReactDOM from "react-dom/client";
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from "react-router-dom";
import App from "./App.tsx";
import Home from "./components/routes/Home.tsx";
import Materiais from "./components/routes/Materiais.tsx";
import "./assets/styles/main.scss";
import Contato from "./components/routes/Contato.tsx";
import TrabalheConosco from "./components/routes/TrabalheConosco.tsx";
import Blog from "./components/routes/Blog.tsx";
import Post from "./components/routes/Posts.tsx";
import Servicos from "./components/routes/servicos/Servicos.tsx";
import Online from "./components/routes/servicos/online/Online.tsx";
import Estrategias from "./components/routes/servicos/estrategias/Estrategias.tsx";
import Assessorias from "./components/routes/servicos/estrategias/Assessorias.tsx";
import ConsultoriaVendasOnline from "./components/routes/servicos/estrategias/ConsultoriaVendasOnline.tsx";
import EstrategiasMarketing from "./components/routes/servicos/estrategias/estategias-de-marketing/EstrategiasMarketing.tsx";
import Pesquisa from "./components/routes/servicos/estrategias/Pesquisa.tsx";
import Planejamento from "./components/routes/servicos/estrategias/Planejamento.tsx";
import MarketingBusca from "./components/routes/servicos/estrategias/estategias-de-marketing/MarketingBusca.tsx";
import MarketingDireto from "./components/routes/servicos/estrategias/estategias-de-marketing/MarketingDireto.tsx";
import MarketingIndireto from "./components/routes/servicos/estrategias/estategias-de-marketing/MarketingIndireto.tsx";
import Endomarketing from "./components/routes/servicos/estrategias/estategias-de-marketing/Endomarketing.tsx";
import MarketingDigital from "./components/routes/servicos/online/marketing-digital/MarketingDigital.tsx";
import MidiasSociais from "./components/routes/servicos/online/marketing-digital/MidiasSociais.tsx";
import AnunciosPatrocinados from "./components/routes/servicos/online/marketing-digital/AnunciosPatrocinados.tsx";
import CriacaoSites from "./components/routes/servicos/online/marketing-digital/CriacaoSites.tsx";
import SeoOtimizacao from "./components/routes/servicos/online/marketing-digital/SeoOtimizacao.tsx";
import MonitoramentoMarca from "./components/routes/servicos/online/MonitoramentoMarca.tsx";
import AutomacaoMarketing from "./components/routes/servicos/online/marketing-digital/AutomacaoMarketing.tsx";
import MarketingConteudo from "./components/routes/servicos/online/MarketingConteudo.tsx";
import GoogleStreetViewTrusted from "./components/routes/servicos/online/GoogleStreetViewTrusted.tsx";
import Offline from "./components/routes/servicos/offline/Offline.tsx";
import Design from "./components/routes/servicos/offline/Design.tsx";
import FotografiaPublicitaria from "./components/routes/servicos/offline/FotografiaPublicitaria.tsx";
import Videos from "./components/routes/servicos/offline/Videos.tsx";
import MidiaImpressa from "./components/routes/servicos/offline/MidiaVeiculo/MidiaImpressa.tsx";
import OutOfHome from "./components/routes/servicos/offline/MidiaVeiculo/OutOfHome.tsx";
import PlacasFachadas from "./components/routes/servicos/offline/PlacasFachadas.tsx";
import ProjetosGraficos from "./components/routes/servicos/offline/ProjetosGraficos.tsx";
import TotemPublicitario from "./components/routes/servicos/offline/TotemPublicitario.tsx";
import Display from "./components/routes/servicos/offline/Display.tsx";
import PropagandaRadio from "./components/routes/servicos/offline/PropagandaRadio.tsx";
import PropagandaRevista from "./components/routes/servicos/offline/PropagandaRevista.tsx";
import PropagandaTelevisao from "./components/routes/servicos/offline/PropagandaTelevisao.tsx";
import SinalizacaoInterna from "./components/routes/servicos/offline/SinalizacaoInterna.tsx";
import Ilustracoes2D3D from "./components/routes/servicos/offline/Ilustracoes2D3D.tsx";
import OutrasMidias from "./components/routes/servicos/offline/MidiaVeiculo/OutrasMidias.tsx";
import Portfolios from "./components/routes/Portfolios.tsx";
import Portfolio from "./components/routes/Portfolio.tsx";
import PoliticaPrivacidade from "./components/routes/PoliticaPrivacidade.tsx";
import MarketingVarejo from "./components/routes/servicos/online/MarketingVarejo.tsx";
import NotFound from "./components/routes/NotFound.tsx";
import MarketingPessoal from "./components/routes/servicos/estrategias/estategias-de-marketing/MarketingPessoal.tsx";
import MidiaVeiculos from "./components/routes/servicos/offline/MidiaVeiculo/MidiaVeiculo.tsx";
import EletronicaDigital from "./components/routes/servicos/offline/MidiaVeiculo/EletronicaDigital.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "politica-de-privacidade",
        element: <PoliticaPrivacidade />,
      },
      {
        path: "servicos",
        element: <Servicos />,
      },
      {
        path: "portfolio",
        element: <Portfolios />,
      },
      {
        path: "portfolio/:alias",
        element: <Portfolio />,
      },
      {
        path: "servicos/estrategias",
        element: <Estrategias />,
      },
      {
        path: "servicos/estrategias/assessorias",
        element: <Assessorias />,
      },
      {
        path: "servicos/estrategias/consultoria-de-vendas-online",
        element: <ConsultoriaVendasOnline />,
      },
      {
        path: "servicos/estrategias/estrategias-de-marketing",
        element: <EstrategiasMarketing />,
      },
      {
        path: "servicos/estrategias/estrategias-de-marketing/marketing-de-busca",
        element: <MarketingBusca />,
      },
      {
        path: "servicos/estrategias/estrategias-de-marketing/marketing-direto",
        element: <MarketingDireto />,
      },
      {
        path: "servicos/estrategias/estrategias-de-marketing/marketing-indireto",
        element: <MarketingIndireto />,
      },
      {
        path: "servicos/estrategias/estrategias-de-marketing/endomarketing",
        element: <Endomarketing />,
      },
      {
        path: "servicos/estrategias/pesquisa",
        element: <Pesquisa />,
      },
      {
        path: "/servicos/estrategias/estrategias-de-marketing/marketing-pessoal",
        element: <MarketingPessoal />,
      },
      {
        path: "servicos/estrategias/planejamento",
        element: <Planejamento />,
      },
      {
        path: "servicos/online",
        element: <Online />,
      },
      {
        path: "servicos/online/marketing-digital",
        element: <MarketingDigital />,
      },
      {
        path: "servicos/online/marketing-digital/midias-sociais",
        element: <MidiasSociais />,
      },
      {
        path: "servicos/online/marketing-digital/anuncios-patrocinados",
        element: <AnunciosPatrocinados />,
      },
      {
        path: "servicos/online/marketing-digital/criacao-de-sites",
        element: <CriacaoSites />,
      },
      {
        path: "servicos/online/marketing-digital/seo-otimizacao",
        element: <SeoOtimizacao />,
      },
      {
        path: "servicos/online/marketing-digital/automacao-de-marketing",
        element: <AutomacaoMarketing />,
      },
      {
        path: "servicos/online/monitoramento-de-marca",
        element: <MonitoramentoMarca />,
      },
      {
        path: "servicos/online/marketing-de-varejo",
        element: <MarketingVarejo />,
      },
      {
        path: "servicos/online/google-street-view-trusted",
        element: <GoogleStreetViewTrusted />,
      },
      {
        path: "servicos/online/marketing-de-conteudo",
        element: <MarketingConteudo />,
      },
      {
        path: "servicos/offline",
        element: <Offline />,
      },
      {
        path: "servicos/offline/design",
        element: <Design />,
      },
      {
        path: "servicos/offline/fotografia-publicitaria",
        element: <FotografiaPublicitaria />,
      },
      {
        path: "servicos/offline/videos",
        element: <Videos />,
      },
      {
        path: "servicos/offline/midia-e-veiculo/impressa",
        element: <MidiaImpressa />,
      },
      {
        path: "/servicos/offline/midia-e-veiculo",
        element: <MidiaVeiculos />,
      },
      {
        path: "/servicos/offline/midia-e-veiculo/eletronica-digital",
        element: <EletronicaDigital />,
      },
      {
        path: "servicos/offline/midia-e-veiculo/out-of-home",
        element: <OutOfHome />,
      },
      {
        path: "servicos/offline/midia-e-veiculo/outras/",
        element: <OutrasMidias />,
      },
      {
        path: "servicos/offline/placas-e-fachadas",
        element: <PlacasFachadas />,
      },
      {
        path: "servicos/offline/projetos-graficos",
        element: <ProjetosGraficos />,
      },
      {
        path: "servicos/offline/totem-publicitario",
        element: <TotemPublicitario />,
      },
      {
        path: "servicos/offline/display",
        element: <Display />,
      },
      {
        path: "servicos/offline/propaganda-de-radio",
        element: <PropagandaRadio />,
      },
      {
        path: "servicos/offline/propaganda-de-revista",
        element: <PropagandaRevista />,
      },
      {
        path: "servicos/offline/propaganda-de-televisao",
        element: <PropagandaTelevisao />,
      },
      {
        path: "servicos/offline/sinalizacao-interna",
        element: <SinalizacaoInterna />,
      },
      {
        path: "servicos/offline/ilustracoes-2d-e-3d",
        element: <Ilustracoes2D3D />,
      },
      {
        path: "blog",
        element: <Blog />,
      },
      {
        path: "blog/:alias",
        element: <Post />,
      },
      {
        path: "materiais",
        element: <Materiais />,
      },
      {
        path: "contato",
        element: <Contato />,
      },
      {
        path: "jobs-vagas-oportunidades",
        element: <TrabalheConosco />,
      },
      {
        path: "*",
        element: <Navigate to="/404" />,
      },
      {
        path: "/404",
        element: <NotFound />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
