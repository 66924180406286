import { IoClose } from "react-icons/io5";
import { IoLogoWhatsapp } from "react-icons/io";
import { LuPhone } from "react-icons/lu";
import { IoIosMail } from "react-icons/io";

type openContactsProps = {
    openContacts: () => void;
}

const Contacts: React.FC<openContactsProps> = ({openContacts}) => {
    return (
        <>
        <div className="closeMenu" onClick={openContacts}>
            <IoClose />
            </div>                
            <ul>
                <li>
                    <a rel="nofollow noopener" title="Mandar WhatsApp para a Boa Imagem" href="tel:6139631444">
                        <LuPhone />
                        (61) 3963-1444
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="nofollow noopener" title="Ligar para a Boa Imagem" href="https://wa.me/556139631444">
                        <IoLogoWhatsapp />
                        (61) 3963-1444
                    </a>
                </li>
                <li>
                    <a target="_blank" rel="nofollow noopener" title="Mandar e-mail para a Boa Imagem" href="mailto:atendimento@boaimagem.tv">
                        <IoIosMail />
                        atendimento@boaimagem.tv
                    </a>
                </li>
            </ul>
         </>
    );
}

export default Contacts;