import logo from "../../assets/images/logo-boa-imagem.webp";
import { IoLogoWhatsapp } from "react-icons/io";
import { LuPhone } from "react-icons/lu";
import { IoIosMail } from "react-icons/io";
import { MdFacebook } from "react-icons/md";
import { RiInstagramFill } from "react-icons/ri";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaYoutube, FaLinkedin } from "react-icons/fa";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="section_padding clip_top text_center">
        <div className="container">
          <img
            src={logo}
            width="162"
            height="33"
            alt="Logo: Boa Imagem - Marketing e Publicidade"
            title="Boa Imagem - Marketing e Publicidade"
          />

          <h2>
            Quero ter uma <strong>Boa Imagem</strong>!
          </h2>
          <a
            className="btn"
            target="_blank"
            rel="nofollow noopener"
            title="Ligar para a Boa Imagem"
            href="https://wa.me/556139631444"
          >
            Entrar em contato
          </a>

          <div className="box_two_columns">
            <a
              rel="nofollow noopener"
              title="Mandar WhatsApp para a Boa Imagem"
              href="tel:6139631444"
            >
              <LuPhone />
              (61) 3963-1444
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ligar para a Boa Imagem"
              href="https://wa.me/556139631444"
            >
              <IoLogoWhatsapp />
              (61) 3963-1444
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Mandar e-mail para a Boa Imagem"
              href="mailto:atendimento@boaimagem.tv"
            >
              <IoIosMail />
              atendimento@boaimagem.tv
            </a>
          </div>

          <div className="box_four_columns">
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver Boa Imagem no Facebook"
              href="https://www.facebook.com/boaimagem"
            >
              <MdFacebook />
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver Boa Imagem no Instagram"
              href="https://www.instagram.com/boa_imagem"
            >
              <RiInstagramFill />
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver Boa Imagem no Twitter"
              href="https://twitter.com/BoaImagem"
            >
              <FaSquareXTwitter />
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver Boa Imagem no Youtube"
              href="https://www.youtube.com/user/Boalmagem"
            >
              <FaYoutube />
            </a>
            <a
              target="_blank"
              rel="nofollow noopener"
              title="Ver Boa Imagem no Linkedin"
              href="https://www.linkedin.com/company/boa-imagem"
            >
              <FaLinkedin />
            </a>
          </div>

          <div id="rights">
            <p>
              <Link to={"/politica-de-privacidade"}>
                Política de Privacidade
              </Link>
            </p>
            <p>Todos os direitos reservados &copy;</p>
            <p>Feito com excelência pela Boa Imagem&reg;</p>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
