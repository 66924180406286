import Breadcrumbs from "../layouts/Breadcrumbs";
import rocket from '../../assets/images/lancamento.gif';
import { Link } from "react-router-dom";
import { useEffect } from "react";

const TrabalheConosco = () => {

    useEffect(() => {
        document.title = "Trabalhe conosco - Oportunidades em marketing, publicidade e web";
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
          metaDescription.setAttribute(
            "content",
            "Trabalhe conosco na Agência Boa Imagem! São diversas vagas em Jobs pontuais e oportunidades de emprego para comunicação, marketing, publicidade e Web"
          );
        }
    }, []);

    const breadcrumbsItens = [{
        name: 'Trabalhe Conosco',
        alias: '/jobs-vagas-oportunidades'
    }]

    return (
        <>
        <Breadcrumbs title='Trabalhe Conosco' items={breadcrumbsItens}/>
        <section id="workWithUsPage">
            <div className='relative bg_secundary section_padding_clip text_center'>
                <div className='container'>
                    <div className="box_paragraph">
                        <p className="without_padding"><b>ATENÇÃO: Você realmente quer fazer parte desta equipe?</b> Fique atento nas dicas abaixo para concorrer a uma das vagas! Valorize o seu passe e demonstre estar apto a realizar trabalhos profissionais de alta qualidade em uma agência que tem um modelo de negócios diferenciado, um posicionamento de mercado único e está realmente focada em entregar resultados para os clientes.</p>
                    </div>

                    <div className="box_two_columns mt-30">
                        <h2>Quer começar bem? Não envie somente o seu currículo! Seja Criativo e escreva uma mensagem de apoio. Destaque o seu talento e seus diferenciais de forma interessante.</h2>
                        <img width='211' height='241' src={rocket} title="As Vagas de emprego em comunicação que vão lançar você na sua carreira profissional" alt="Foguete de lançamento | Vagas de publicidade e marketing"/>
                    </div>

                    <h4 className="mt-30">Se você busca uma de nossas vagas de emprego ou pensa em trabalhar conosco em qualquer job comece a sua apresentação sendo criativ@!
                    No ambiente de agências todas as vagas por mais simples que sejam são para pessoas criativas e interessantes…</h4>

                    <p>A Boa Imagem é uma marca registrada, <Link to="/sobre-nos">clique aqui e conheça o Conceito da Marca</Link>.</p>

                    <p>Caso queira saber mais veja também a <Link to="/sobre-nos">Missão e Valores da Marca</Link>.</p>

                    <p>Buscamos o melhor time de profissionais para a nossa equipe…</p>

                    <p>Pessoas especiais com um brilho no olhar, que tenham fácil convivência, boas de coração e mentes brilhantes!</p>

                    <div className="box_paragraph">
                        <h2>Por isso pedimos que você tome conhecimento de alguns requisitos de perfil para as vagas antes de iniciar a sua apresentação:</h2>

                        <h3><b>Requisitos de Perfil Pessoal:</b></h3>
                        <ul>
                            <li>Ser Sociável</li>
                            <li>Evitar discussões no dia a dia</li>
                            <li>Ter um bom relacionamento com família e amigos</li>
                        </ul>

                        <h3><b>Requisitos de Perfil Profissional:</b></h3>
                        <ul className="list">
                            <li>Gostar do que faz</li>
                            <li>Criatividade nos mínimos detalhes</li>
                            <li>Proatividade para buscar melhorias e soluções quando não tiver demanda específica</li>
                            <li>Buscar sempre otimizar o que faz</li>
                            <li>Estar antenado às novas tecnologias</li>
                            <li>Interesse por novos conhecimentos e ferramentas</li>
                            <li>Realizar e criar testes para os projetos que desenvolveu</li>
                            <li>Se organizar, Planejar e Documentar</li>
                            <li>Preocupação com Segurança</li>
                            <li>Confidencialidade</li>
                            <li>Visão do cliente</li>
                            <li>Foco em performance e resultados</li>
                            <li>Se antecipar aos problemas</li>
                            <li>Disposição para projetos e metodologias ágeis</li>
                            <li>Comunicação aberta e debate saudável</li>
                            <li>Habilidade para propor e falar sobre ideias e tecnologias</li>
                            <li>Aberto ao desenvolvimento pessoal e verdadeiro trabalho em equipe</li>
                            <li>Noções de Testes A/B</li>
                            <li>Visão geral sobre os Processos de Comunicação</li>
                            <li>Conhecimentos gerais sobre Marketing</li>
                        </ul>

                        <h3><b>Conquistas que vamos buscar juntos:</b></h3>
                        <ul className="list">
                            <li>Projetos 100% personalizados</li>
                            <li>Entregar projetos com agilidade e qualidade</li>
                            <li>Desenvolvimento e melhoras pessoais e da equipe</li>
                            <li>Atualização de mercado tecnológica gradual</li>
                            <li>Trabalhar com preocupação voltada para segurança e qualidade</li>
                            <li>Automação de processos manuais</li>
                            <li>Inovar na busca de soluções de problemas</li>
                            <li>Melhorar nossos processos de desenvolvimento e de negócios</li>
                            <li>Sucesso e satisfação do cliente</li>
                            <li>Ser parte do time</li>
                        </ul>

                        <h3><b>O seu principal objetivo aqui é:</b></h3>
                        <p>Atuar de forma ágil e integrada com a equipe no desenvolvimento de novos projetos, participando da evolução técnica gradual dos resultados obtidos em cada um deles;</p>
                    </div>

                    <h3 className="mt-30"><b>O que <span className="text-danger">NÃO queremos:</span></b></h3>
                    <ul className="list">
                        <li>Alguém que foque apenas no seu resultado e não no da equipe ou da empresa</li>
                        <li>Alguém não confortável com ambiente multi disciplinar com demandas variadas</li>
                        <li>Alguém que não goste de auto-avaliação e e receber críticas construtivas</li>
                        <li>Alguém sem senso de prioridade e proatividade</li>
                    </ul>

                    <h3><b>BENEFÍCIOS:</b></h3>
                    <ul className="list">
                        <li>Incentivo a cursos / atualizações / especializações;</li>
                        <li>Ambiente agradável;</li>
                        <li>Oportunidade de crescimento profissional e pessoal.</li>
                    </ul>
                    <p><b>OBS:</b> Caso esteja procurando por vagas de estágio, além de enviar o seu currículo você também precisa estar cadastrado inicialmente em serviços de Administradores de Estágio como <a href="http://www.catho.com.br/contratacao-de-estagiario/estagio/" target="_blank" rel="noopener noreferrer">CATHO e</a> <a href="https://www.ciee.org.br/portal/estudantes/" target="_blank" rel="noopener noreferrer">CIEE</a> ou estar na fase de acordo com a <a href="http://portal.mec.gov.br/cne/arquivos/pdf/pceb35_03.pdf" target="_blank" rel="noopener noreferrer">Lei do estágio supervisionado</a> pela sua universidade.</p>
      
                </div>
            </div>
        </section>
        </>
    );
}

export default TrabalheConosco;    