import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { PostInterface } from "../interfaces/PostInterface";

const Post = () => {
  const [dataPost, setDataPost] = useState<PostInterface | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      try {
        const res = await fetch(
          "https://boaimagem.org/api/posts.php/?limit=1&content_limit=110",
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        const post = await res.json();
        setDataPost(post.data[0]);
      } catch (err) {
        console.log(err);
      }
    };
    fetchPost();
  }, []);

  return (
    <>
      {dataPost && (
        <>
          <div className="box_two_columns">
            <h3>{dataPost.post_title}</h3>
            <img
              width="400"
              height="185"
              alt={dataPost.post_title}
              title={dataPost.post_title}
              src={`https://boaimagem.org/images/noticias-webp/${dataPost.imagem.replace(
                "jpg",
                "webp"
              )}`}
            />
          </div>

          <p>{dataPost.text.replace(/<\/?[^>]+(>|$)/g, "")}...</p>
          <Link className="read_more" to={`/blog/${dataPost.post_name}`}>
            Leia mais
          </Link>
        </>
      )}
    </>
  );
};

export default Post;
